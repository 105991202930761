<template>
  <main>
    <div class="container prose mx-auto my-24">
      <ContentDoc class="mx-auto max-w-screen-lg md:py-24">
        <template #not-found>
          <div class="mt-4 w-full divide-y divide-gray-100 rounded-xl border border-gray-100 px-8 py-8 text-center md:py-20">
            <!-- 404 Page -->
            <section class="relative flex w-full flex-col items-center justify-center">
              <div class="flex flex-col">
                <h1 class="mb-0 pb-0 text-8xl/none font-bold">404</h1>
                <p class="text-lg/none text-gray-500">Sorry about this, we're always moving things.</p>
                <NuxtLink to="/contact"><Button>Need Help?</Button></NuxtLink>
              </div>
            </section>
          </div>
        </template>
      </ContentDoc>
    </div>
    <BlockFooter />
  </main>
</template>

<script setup>
useSeoMeta({
  robots: 'noindex, nofollow',
})
</script>
